import 'flowbite';

import * as ActiveStorage from '@rails/activestorage';

ActiveStorage.start();

import './application.scss';

import './stimulus';

import './map';
